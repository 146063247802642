export const getZendeskTemplateUpdatedPrescription = ({ values }) => {
  return `Maxicare Card Number: ${values?.maxicareCardNumber}\nFirst name: ${
    values?.firstName
  }\nLast name: ${values?.lastName}\nEmail: ${values?.email}\nMobile number: ${
    values?.mobileNumber
  }\nCompany: ${values?.company}\nEmployee ID: ${
    values?.employeeNumber
  }\n\nPrescription Date: ${values?.prescriptionDate?.month?.value} ${
    values?.prescriptionDate?.date?.value
  }, ${values?.prescriptionDate?.year?.value}\nDoctor's name: ${
    values?.prescribingDoctor
  }\nDoctor's PRC Number: ${
    values?.prcNumber
  }\n\nPrescribed Medicines\n${values?.medicines
    ?.map((medicine) => `${medicine.drugName}, ${medicine.dailyIntake}`)
    ?.join("\n")}`
}

export const getZendeskTemplateUpdatedPersonalDetails = ({
  values,
  updatedPrescription,
}) => {
  let { deliveryAddress, mobileNumber, siteAddress, email } = values
  let {
    barangay,
    addressType,
    officeAddress,
    notes,
    province,
    city,
    streetAddress,
    houseNumber,
  } = deliveryAddress

  let zendeskTemplate = `Maxicare Card Number: ${updatedPrescription?.maxicareCardNumber}\nFirst name: ${updatedPrescription?.firstName}\nLast name: ${updatedPrescription?.lastName}\nEmployee ID: ${updatedPrescription?.employeeNumber}\nEmail: ${updatedPrescription?.email}\nMobile Number: ${updatedPrescription?.mobileNumber}\nCompany: ${updatedPrescription?.company}\n\n`

  if (email) zendeskTemplate += `Updated Email: ${email}\n`
  if (mobileNumber)
    zendeskTemplate += `Updated Mobile Number: ${mobileNumber}\n`
  if (siteAddress?.value)
    zendeskTemplate += `Updated Site Address: ${siteAddress?.value || "N/A"}\n`
  else if (officeAddress?.value)
    zendeskTemplate += `${
      officeAddress
        ? `Updated Site Address: ${officeAddress?.value || "N/A"}\n`
        : ""
    }`
  if (deliveryAddress)
    zendeskTemplate += `${
      streetAddress || houseNumber
        ? `Updated Street Address: ${
            deliveryAddress?.addressType === "Home"
              ? deliveryAddress?.streetAddress || "N/A"
              : (deliveryAddress?.houseNumber || "N/A") +
                " " +
                (deliveryAddress?.streetAddress || "N/A")
          }\n`
        : ""
    }${
      province?.value
        ? `Updated Province: ${deliveryAddress?.province?.value || "N/A"}\n`
        : ""
    }${
      city?.value
        ? `Updated City: ${deliveryAddress?.city?.value || "N/A"}\n`
        : ""
    }${
      barangay?.value ? `Updated Barangay: ${barangay?.value || "N/A"}\n` : ""
    }${addressType ? `Updated Address Type: ${addressType}\n` : ""}${
      notes
        ? `Updated Delivery Notes: ${deliveryAddress?.notes || "N/A"}\n`
        : ""
    }
    `

  return zendeskTemplate
}

export const getZendeskTemplateRefillCancellation = ({ values }) => {
  return `Maxicare Card Number: ${values?.maxicareCardNumber}\nFirst name: ${
    values?.firstName
  }\nLast name: ${values?.lastName}\nEmail: ${values?.email}\nMobile number: ${
    values?.mobileNumber
  }\nCompany: ${values?.company}\nEmployee ID: ${
    values?.employeeNumber
  }\n\nDiscontinued Medicines\n${values?.cancelledMedicines
    ?.map((medicine) => medicine.drugName)
    ?.join("\n")}`
}

export const getZendeskTemplateTeleconsult = ({ values }) => {
  const printDateValue = (date) => {
    if (!date.month.value || !date.date.value || !date.year?.value) return "N/A"
    return `${date.month.value} ${date.date.value} ${date.year?.value}`
  }

  let laboratoryResults = ``
  if (values?.issuedLaboratoryTests?.includes("FBS"))
    laboratoryResults = `${laboratoryResults}\n\nFBS Date Tested: ${printDateValue(
      values?.fbsDateOfTest
    )}\nFBS Results: ${values?.fbsResult} ${values?.fbsUnitOfMeasure}`

  if (values?.issuedLaboratoryTests?.includes("Hba1C"))
    laboratoryResults = `${laboratoryResults}\n\nHba1C Date Tested: ${printDateValue(
      values?.hba1cDateOfTest
    )}\nHba1C Results: ${values?.hba1cResult} ${values?.hba1cUnitOfMeasure}`

  if (values?.issuedLaboratoryTests?.includes("LDL Cholesterol"))
    laboratoryResults = `${laboratoryResults}\n\nLDL Date Tested: ${printDateValue(
      values?.ldlDateOfTest
    )}\nLDL Results: ${values?.ldlResult} ${values?.ldlUnitOfMeasure}`

  return `First name: ${values?.firstName}\nLast name: ${
    values?.lastName
  }\nEmail: ${values?.email}\nMobile number: ${
    values?.mobileNumber
  }\nCompany: ${values?.company}\nEmployee ID: ${
    values?.employeeNumber
  }\n\nMaxicare Card Number ${values?.maxicareCardNumber}\nHeight: (cm/ft) ${
    values?.height?.value
  }\nWeight: (kg/lbs) ${values?.weight?.value}\nDo you currently smoke?: ${
    values?.frequencyOfSmoking
  }\nMedical History: ${values?.medicalHistory?.join(
    ", "
  )}\nOther Medical History: ${
    values?.otherMedicalHistory || "N/A"
  }\nFamily Medical History: ${values?.familyMedicalHistory.join(
    ", "
  )}\nOther Family Medical History: ${
    values?.otherFamilyMedicalHistory || "N/A"
  }\nMaxicare Support: ${values?.maxicareOfferings?.join(
    ", "
  )}\nOther Maxicare Support: ${
    values?.otherMaxicareOfferings || "N/A"
  }\nRemarks: ${values?.remarks}`
}